import Ready from '@/utils/ready';

const selector = '[data-ga-sender]';

Ready.watch(selector, (element: any) => {
  const type = element.dataset.gaSender;

  if (type === 'brochure' && (window as any).ga && (window as any).loggedUser) {
    if (!element.href.includes('&clientId') && (window as any).gaGlobal.vid) {
      element.href = `${element.href}&clientId=${(window as any).gaGlobal.vid}`;
    }
  }

  const redirect = () => {
    window.location = (element as any).href;
  };

  element.addEventListener('click', (e: any) => {
    e.preventDefault();

    if ((window as any).ga) {
      let { currentPageName, dataLayer } = window as any;

      const timeout = setTimeout(()=>{
        redirect() 
      }, 2000)
      dataLayer.push({
        event: `form${type ? `_${type}` : ''}_open`, 
        page: `${currentPageName}`,
        eventCallback: function(){ 
          clearTimeout(timeout)
          redirect() 
        }
      });     
    }else {
      redirect();
    }
  })
});
